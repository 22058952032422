import React, { useEffect } from "react";
import Aos from "aos";
import { Helmet } from "react-helmet";

import Footer from "../shared/layout/Footer";
import Header from "../shared/layout/Header";
import Newsletter from "../components/Newsletter";
import logo from "../assets/image/prettywomen.png";
const Massage = () => {
  useEffect(() => {
    Aos.init({ delay: 1000 });
  });
  return (
    <>
      <Helmet>
        <meta name="description" content="pretty women massage service" />
        <meta
          property="og:url"
          content="https://www.prettywomen.ma/services/massage"
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="pretty women massage service" />
        <meta
          property="og:description"
          content="pretty women massage service"
        />
        <meta property="og:image" content="" />
        <title>Massage service - Pretty women services</title>
      </Helmet>
      <Header />
      <main className="services-wrapper pt-5">
        <section className="container" data-aos="fade-right">
          <div className="text-center services-text d-flex flex-column align-items-center">
            <h1>Massage</h1>
            <p className="text-muted w-75">
              Nous offrons des services de massage effectués conformément aux
              normes professionnelles les plus courantes. Avant chaque
              traitement, nous procédons à un examen approfondi pour déterminer
              la prestation la mieux adaptée à vos besoins.
            </p>
          </div>
          <article className="service-back-img d-flex flex-column  justify-content-center ps-5 text-white my-5"></article>
        </section>
        <section className="container py-5" data-aos="fade-right">
          <div className="row">
            <div className="pricing-card-css col-lg-6 col-12 mt-4">
              <article className="d-flex align-items-center text-white">
                <h5>CALIFORNIEN</h5>
                <span className="pricing-card-line"></span>
                <figure>
                  <img
                    className="card-price-css"
                    src={logo}
                    alt="pretty women logo"
                  />
                </figure>
              </article>
              <p>
                Massage lent, doux et relaxant permettant une réduction du
                stress et de l’anxiété par une gestuelle chaleureuse et
                enveloppante
              </p>
            </div>
            <div className="pricing-card-css col-lg-6 col-12 mt-4">
              <article className="d-flex align-items-center text-white">
                <h5>SUÉDOIS</h5>
                <span className="pricing-card-line"></span>
                <figure>
                  <img
                    className="card-price-css"
                    src={logo}
                    alt="pretty women logo"
                  />
                </figure>
              </article>
              <p>
                Massage profond dénouant les tensions et apportant une détente
                musculaire.
              </p>
            </div>
            <div className="pricing-card-css col-lg-6 col-12 mt-4">
              <article className="d-flex align-items-center text-white">
                <h5>ORIENTAL BERBÈRE</h5>
                <span className="pricing-card-line"></span>
                <figure>
                  <img
                    className="card-price-css"
                    src={logo}
                    alt="pretty women logo"
                  />
                </figure>
              </article>
              <p>
                Une main de fer dans un gant de velours », gestuelle orientale
                et étirements permettant une détente complète
              </p>
            </div>
            <div className="pricing-card-css col-lg-6 col-12 mt-4">
              <article className="d-flex align-items-center text-white">
                <h5>AYURVÉDIQUE</h5>
                <span className="pricing-card-line"></span>
                <figure>
                  <img
                    className="card-price-css"
                    src={logo}
                    alt="pretty women logo"
                  />
                </figure>
              </article>
              <p>
                Massage énergétique et profond, il permet de ré-harmoniser le
                corps et l’esprit pour retrouver quiétude et bien-être.
              </p>
            </div>
            <div className="pricing-card-css col-lg-6 col-12 mt-4">
              <article className="d-flex align-items-center text-white">
                <h5>NORDIQUE VIVIFIANT</h5>
                <span className="pricing-card-line"></span>
                <figure>
                  <img
                    className="card-price-css"
                    src={logo}
                    alt="pretty women logo"
                  />
                </figure>
              </article>
              <p>
                Un massage profond, tonique et dynamique qui répond à des
                techniques exclusives qui tendent à libérer le corps des
                tensions et à le régénérer. Recommandé aux sportifs.
              </p>
            </div>
          </div>
        </section>
        <Newsletter />
      </main>
      <Footer />
    </>
  );
};

export default Massage;
