import {Link } from 'react-router-dom';
import {HashLink} from 'react-router-hash-link'
import Logo from '../../assets/image/logo.svg'
const Header = () => {
    const handleclick = ()=>{
        document.querySelector(".contact-wrapper-css").classList.toggle("d-none");
        document.querySelector(".background-color-css").classList.toggle("d-none");
    }
  return (
    <header className="header-css ">
        <div className='container'>
            <div className='row justify-content-lg-center justify-content-start align-items-center'>
                <nav className='col-4 d-lg-flex d-none justify-content-between'>
                    <HashLink className='text-decoration-none text-white header-link-css' to="/#acceuil">Acceuil</HashLink>
                    <HashLink className='text-decoration-none text-white header-link-css' to="/#services">Services</HashLink>
                    <HashLink className='text-decoration-none text-white header-link-css' to="/#spacards">Gallerie</HashLink>
                </nav>
                <Link to="/" className='col-lg-2 col-md-3 col-5 d-flex justify-content-lg-center justify-content-start'>
                    <img className='img-fluid logo-css' src={Logo} alt="pretty women"/>
                </Link>
                <nav className='col-4 d-lg-flex d-none justify-content-between'>
                    <HashLink className='text-decoration-none text-white header-link-css' to="/#footer">Contact</HashLink>
                    <HashLink className='text-decoration-none text-white header-link-css' to="/#about">A propos</HashLink>
                    <HashLink className='text-decoration-none text-white header-link-css' to="/#horaires-de-travail">Horaires de travail</HashLink>
                </nav>
                <div className='col-md-9 col-7 d-lg-none d-flex justify-content-end'>
                   {/* <FontAwesomeIcon icon={faBars} className="fs-2 text-white"/> */}
                    <button className='btnhoover bg-white border-0 py-2 px-4 btnhoover' onClick={handleclick}>Réservez</button>
                </div> 
            </div>
        </div>
    </header>
  )
}

export default Header