import React, { useEffect } from "react";
import Aos from "aos";
import { Helmet } from "react-helmet";

import Footer from "../shared/layout/Footer";
import Header from "../shared/layout/Header";
import Newsletter from "../components/Newsletter";
import logo from "../assets/image/prettywomen.png";

const Coiffure = () => {
  useEffect(() => {
    Aos.init({ delay: 1000 });
  });
  const allservices = [
    {
      title: "Coupe de cheveux et coiffage",
    },
    {
      title: "Lavage et coiffage des cheveux",
    },
    {
      title: "Coloration des cheveux",
    },
    {
      title: "Diagnostic personnalisé",
    },
    {
      title: "Choisissez le style",
    },
    {
      title: "Look unique.",
    },
    {
      title: "BALAYGE",
    },
    {
      title: "COLORATION RACINE-SECHAGE NATUREL ",
    },
    {
      title: "MECHES",
    },
    {
      title: "MECHES AVEC COLORATION",
    },
    {
      title: "COLORATION",
    },
    {
      title: "COLORATION AVEC AMONIAQUE",
    },
    {
      title: "COLORATION SANS AMONIAQUE",
    },
    {
      title: "LISSAGE",
    },
    {
      title: "SOINS A BASE DE KERATINE",
    },
    {
      title: "PROTEINE",
    },
    {
      title: "OMBRE",
    },
    {
      title: "TANINO",
    },
    {
      title: "PROTEINE",
    },
    {
      title: "WHITE LOVE",
    },
    {
      title: "OC",
    },
    {
      title: "SOINS DE CHEVEUX",
    },
    {
      title: "SOINS AVEC AMPOULE",
    },
    {
      title: "SOINS AVEC AMPOULE (NORMALE)",
    },
    {
      title: "SOINS AVEC LES HUILES BIO",
    },
  ];
  const services = allservices.map((element) => {
    return (
      <div className="pricing-card-css col-lg-6 col-12 mt-4">
        <article className="d-flex align-items-center text-white">
          <h5>{element.title}</h5>
          <span className="pricing-card-line"></span>
          <figure>
            <img
              className="card-price-css"
              src={logo}
              alt="pretty women logo"
            />
          </figure>
        </article>
        {element.description ? <p>{element.description}</p> : null}
      </div>
    );
  });
  return (
    <>
      <Helmet>
        <meta name="description" content="pretty women coiffure service" />
        <meta
          property="og:url"
          content="https://www.prettywomen.ma/services/coiffure"
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="pretty women coiffure" />
        <meta
          property="og:description"
          content="pretty women coiffure service"
        />
        <meta property="og:image" content="" />
        <title>Coiffure service - Pretty women services</title>
      </Helmet>
      <Header />
      <main className="services-wrapper pt-5">
        <section className="container" data-aos="fade-right">
          <div className="text-center services-text d-flex flex-column align-items-center">
            <h1>Coiffure</h1>
            <p className="text-muted w-75">
              Chez Pretty Women, nous accordons une grande importance à la
              satisfaction de notre clientèle. Pour répondre à vos attentes,
              nous mettons à votre disposition une variété de services qui
              sauront combler vos souhaits.
            </p>
          </div>
          <article className="service-back-img service-back-img-coiffure d-flex flex-column  justify-content-center ps-5 text-white my-5"></article>
        </section>
        <section className="container py-5" data-aos="fade-right">
          <div className="row">{services}</div>
        </section>
        <Newsletter />
      </main>
      <Footer />
    </>
  );
};

export default Coiffure;
