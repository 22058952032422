import React, { useEffect } from "react";
import Aos from "aos";
import { Helmet } from "react-helmet";

import Footer from "../shared/layout/Footer";
import Header from "../shared/layout/Header";
import Newsletter from "../components/Newsletter";
import logo from "../assets/image/prettywomen.png";
const Esthetique = () => {
  useEffect(() => {
    Aos.init({ delay: 1000 });
  });
  const allservices = [
    "SOINS INSTITUT",
    "MAILLOT TRADITIONNEL",
    "MAILLOT ÉCHANCRÉ OU INTÉGRAL",
    "AISSELLES",
    "1/2 JAMBES",
    "JAMBES ENTIÈRES",
    "1/2 BRAS",
    "BRAS ENTIERS",
    "DUVET",
    "SOURCILS",
    "VISAGE",
    "EPILATION COMPLETE",
  ];
  const services = allservices.map((element) => {
    return (
      <div className="pricing-card-css col-lg-6 col-12 mt-4">
        <article className="d-flex align-items-center text-white">
          <h5>{element}</h5>
          <span className="pricing-card-line"></span>
          <figure>
            <img
              className="card-price-css"
              src={logo}
              alt="pretty women logo"
            />
          </figure>
        </article>
      </div>
    );
  });
  return (
    <>
      <Helmet>
        <meta name="description" content="pretty women esthetique service" />
        <meta
          property="og:url"
          content="https://www.prettywomen.ma/services/esthetique"
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="pretty women massage esthetique" />
        <meta
          property="og:description"
          content="pretty women massage esthetique"
        />
        <meta property="og:image" content="" />
        <title>Esthetique service - Pretty women services</title>
      </Helmet>
      <Header />
      <main className="services-wrapper pt-5">
        <section className="container" data-aos="fade-right">
          <div className="text-center services-text d-flex flex-column align-items-center">
            <h1>Esthetique</h1>
            <p className="text-muted w-75">
              Le centre de beauté et spa Pretty Women vous invite à découvrir
              ses soins du visage, une célébration de la féminité. Notre
              objectif est de vous offrir une expérience inoubliable,
              personnalisée et sensorielle, afin de vous faire vivre un moment
              exceptionnel.
            </p>
          </div>
          <article className="service-back-img service-back-img-esthetique d-flex flex-column  justify-content-center ps-5 text-white my-5"></article>
        </section>
        <section className="container py-5" data-aos="fade-right">
          <div className="row">{services}</div>
        </section>
        <Newsletter />
      </main>
      <Footer />
    </>
  );
};

export default Esthetique;
