import React, { useEffect } from "react";
import Aos from "aos";
import { Helmet } from "react-helmet";

import Footer from "../shared/layout/Footer";
import Header from "../shared/layout/Header";
import Newsletter from "../components/Newsletter";
import logo from "../assets/image/prettywomen.png";

const Soinsdevisage = () => {
  useEffect(() => {
    Aos.init({ delay: 1000 });
  });
  const allservices = [
    { title: "SOINS COMPLET DE VISAGE" },
    { title: "MODELAGE DE VISAGE – GOMMAGE DE VISAGE " },
    { title: "NETTOYAGE DE VISE" },
    { title: "MASQUE DE VISAGE" },
    { title: "MASSAGE HYDRATATION " },
    { title: "SOINS DE VISAGE" },
    { title: "GOMMAGE - MASQUE – MODELAGE" },
  ];
  const services = allservices.map((element) => {
    return (
      <div className="pricing-card-css col-lg-6 col-12 mt-4">
        <article className="d-flex align-items-center text-white">
          <h5>{element.title}</h5>
          <span className="pricing-card-line"></span>
          <figure>
            <img
              className="card-price-css"
              src={logo}
              alt="pretty women logo"
            />
          </figure>
        </article>
        {element.description ? <p>{element.description}</p> : null}
      </div>
    );
  });
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="pretty women soins de visage service"
        />
        <meta
          property="og:url"
          content="https://www.prettywomen.ma/services/soinsdevisage"
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="pretty women soins de visage" />
        <meta
          property="og:description"
          content="pretty women soins de visage service"
        />
        <meta property="og:image" content="" />
        <title>soins de visage service - Pretty women services</title>
      </Helmet>
      <Header />
      <main className="services-wrapper pt-5">
        <section className="container" data-aos="fade-right">
          <div className="text-center services-text d-flex flex-column align-items-center">
            <h1>Soins de visage</h1>
            <p className="text-muted w-75">
              Nous proposons une gamme complète de soins pour le visage,
              comprenant des massages, des nettoyages en profondeur, des
              gommages, des masques, des hydratations, des modelages, ainsi que
              des soins partiels incluant gommage, masque et modelage.
            </p>
          </div>
          <article className="service-back-img service-back-img-soins-de-visage d-flex flex-column  justify-content-center ps-5 text-white my-5"></article>
        </section>
        <section className="container py-5" data-aos="fade-right">
          <div className="row">{services}</div>
        </section>
        <Newsletter />
      </main>
      <Footer />
    </>
  );
};

export default Soinsdevisage;
