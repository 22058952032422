import Header from "../shared/layout/Header";
import Footer from "../shared/layout/Footer";
const Thankyou = () => {
  return (
    <>
      <Header />
      <div className="thank-you-wrapper">
        <h2 className="container thankyou-page">
            Votre demande a été traitée avec succès! Nous sommes très honorés de
            vous compter parmi nos clients. Vous recevrez un appel de la part de
            notre service client dans le plus bref délai pour la confirmer. Merci de
            votre confiance!
        </h2>
      </div>
      <Footer />
    </>
  );
};

export default Thankyou;
