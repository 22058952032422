import React, { useEffect } from "react";
import Aos from "aos";
import { Helmet } from "react-helmet";

import Footer from "../shared/layout/Footer";
import Header from "../shared/layout/Header";
import Newsletter from "../components/Newsletter";
import logo from "../assets/image/prettywomen.png";

const Onglerie = () => {
  useEffect(() => {
    Aos.init({ delay: 1000 });
  });
  const allservices = [
    { title: "Vernis permanent mains & pieds" },
    { title: "Beauté des mains" },
    { title: "Beauté des pieds" },
    { title: "Nail art" },
    { title: "Vernis classique" },
    { title: "Pose d’ongles gel mains / pieds & vernis classique" },
    { title: "Pose d’ongles gel mains / pieds & couleur permanente" },
    { title: "Remplissage gel mains / pieds & vernis classique" },
    { title: "Remplissage gel mains / pieds & couleur permanente" },
    { title: "Vernis permanent mains / pieds" },
    { title: "Duo vernis permanent mains & pieds" },
    { title: "Dépose & soins des ongles mains / pieds" },
    { title: "Dépose Express mains / pieds" },
    { title: "Mise en beauté des ongles" },
    { title: "Soin paraffine" },
    { title: "Vernis classique mains" },
    { title: "Soin des ongles" },
    { title: "Soin balnéo relaxant" },
    { title: "Soin balnéo énergisant" },
    { title: "Soin paraffine" },
    { title: "Callosités express" },
    { title: "Soins Anti-Callosités" },
    { title: "Déco nail art simple sur les ongles" },
    { title: "Déco nail art artistique sur les ongles" },
    { title: "Soin Hydra-Cocooning" },
    { title: "Soin Revitalisant Oriental" },
    { title: "Soin Régénérant Eclat" },
    { title: "Ongle normal classique" },
    { title: "Faux ongles permanent" },
  ];
  const services = allservices.map((element) => {
    return (
      <div className="pricing-card-css col-lg-6 col-12 mt-4">
        <article className="d-flex align-items-center text-white">
          <h5>{element.title}</h5>
          <span className="pricing-card-line"></span>
          <figure>
            <img
              className="card-price-css"
              src={logo}
              alt="pretty women logo"
            />
          </figure>
        </article>
        {element.description ? <p>{element.description}</p> : null}
      </div>
    );
  });
  return (
    <>
      <Helmet>
        <meta name="description" content="pretty women onglerie service" />
        <meta
          property="og:url"
          content="https://www.prettywomen.ma/services/onglerie"
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="pretty women onglerie" />
        <meta
          property="og:description"
          content="pretty women onglerie service"
        />
        <meta property="og:image" content="" />
        <title>onglerie service - Pretty women services</title>
      </Helmet>
      <Header />
      <main className="services-wrapper pt-5">
        <section className="container" data-aos="fade-right">
          <div className="text-center services-text d-flex flex-column align-items-center">
            <h1>Onglerie</h1>
            <p className="text-muted w-75">
              Notre équipe de professionnels de l'onglerie et de la pose de cils
              propose une variété de prestations, telles que la pose de vernis
              permanent pour les mains et les pieds, des soins de beauté pour
              les mains et les pieds, de la décoration d'ongles, ainsi que la
              pose de vernis classique.
            </p>
          </div>
          <article className="service-back-img service-back-img-onglerie d-flex flex-column  justify-content-center ps-5 text-white my-5"></article>
        </section>
        <section className="container py-5" data-aos="fade-right">
          <div className="row">{services}</div>
        </section>
        <Newsletter />
      </main>
      <Footer />
    </>
  );
};

export default Onglerie;
