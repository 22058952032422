import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import React from "react";
import Lazyloading from "./pages/Lazyloading";
import Error from "./pages/Error";
import Blog from "./pages/Blog";
import Blog2 from "./pages/Blog2";
import Blog3 from "./pages/Blog3";
import ScrollTop from "./helpers/ScrollTop";
import Service from "./pages/Massage";
import Esthetique from "./pages/Esthetique";
import Coiffure from "./pages/Coiffure";
import Soinsdevisage from "./pages/Soinsdevisage";
import Makeup from "./pages/Makeup";
import Onglerie from "./pages/Onglerie";
import Thankyou from "./pages/Thankyou";
const Home  = React.lazy(()=>import('./pages/Home'))

function App() {
  return (
    <BrowserRouter>
      <ScrollTop/>
      <Routes>
        <Route path="/" element={<React.Suspense fallback={<Lazyloading/>}>
          <Home/>
        </React.Suspense>}></Route>
        <Route path="*" element={<Error/>}></Route>
        <Route path="/blog/quel-mascara-pour-moi" element={<Blog/>}></Route>
        <Route path="/blog/prendre-soin-de-soi-avant-d’aller-dormir" element={<Blog2/>}></Route>
        <Route path="/blog/Comment-appliquer-le-fard-à-paupières-pour-les-débutants-en-5-étapes" element={<Blog3/>}></Route>
        <Route path="/services/massage" element={<Service/>}></Route>
        <Route path="/services/esthetique" element={<Esthetique/>}></Route>
        <Route path="/services/coiffure" element={<Coiffure/>}></Route>
        <Route path="/services/soinsdevisage" element={<Soinsdevisage/>}></Route>
        <Route path="/services/make-up" element={<Makeup/>}></Route>
        <Route path="/services/onglerie" element={<Onglerie/>}></Route>
        <Route path="/thankyou" element={<Thankyou/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
